import { writable } from 'svelte/store';
import { browser } from '$app/environment';

/* function checkLoginType() {
	const { subscribe, set } = writable('staff');


	return {
		subscribe,
		set: (newValue) => set(newValue)
	};
}
export const LOGIN_TYPE = checkLoginType(); */

export const PARENT_INFO = writable(
	browser &&
		((localStorage.getItem('PARENT_INFO') !== undefined &&
			JSON.parse(localStorage.getItem('PARENT_INFO'))) ||
			null)
);
PARENT_INFO.subscribe((value) => browser && (localStorage.PARENT_INFO = JSON.stringify(value)));

export const SHOW_PARENT_MODAL = writable(false);
